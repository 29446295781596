.cabeceraBoletin {margin-top: 1rem;}

.bloqueNoticiaBoletin {
    margin: 2.5rem 0;

    h2 {font-size: 1em;}
}

.seccion-boletin,
.acc-highlight .seccion-boletin {
    h2 {font-size: 1em;}
    h2 a {color: #222921;}
    .fecha {font-weight: bold;}
}